@import "../../styles/variable";
.cardLux {
  max-width: 450px;
  width: 100%;
  height: fit-content;
  border: 1px solid $red-color;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 30px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 10px 0 #c1c1c1;
  }
  &_image {
    width: 100%;
    height: 230px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &_name {
    text-align: center;
    margin-top: 15px;
    width: 100%;
    margin-bottom: 0;
  }
  &_book {
    font-weight: 700;
    margin: auto;
    margin-top: 30px;
    width: fit-content;
    padding: 15px 60px;
    text-align: center;
    border-radius: 20px;
    color: white;
  }

  &_link {
    display: block;
  }
  &_disabled {
    pointer-events: none;
  }
}
