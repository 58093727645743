@import "../../styles/variable";

.rules {
  margin: 30px auto;

  &_heading {
    text-align: center;
  }

  &_collapse {
    margin-top: 30px;
  }
  .ant-collapse {
    background-color: transparent;
    border: none;
    .ant-collapse-item > .ant-collapse-header {
      color: $black-color;
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      p {
        padding: 5px 20px;
        border-radius: 8px;
        line-height: 2rem;
        font-size: 16px;
      }
    }
  }
}
