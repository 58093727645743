@import "../../styles/variable";
.language{
    display: flex;
    align-items: center;
    width: fit-content;
    button{
        padding: 5px 20px;
        background-color: $primary-color ;
        color: $white-color;
        border-radius: 12px;
        margin: 0 10px;
    }
}