@import "../../styles/_variable";

.card_lux {
  .card {
    &_item {
      background-color: red;
    }
  }
}
.card {
  &_item {
    cursor: pointer;
    margin: auto;
    position: relative;
    max-width: 252px;
    width: 100%;
    height: fit-content;
    padding: 15px;
    border-radius: 10px;
    box-shadow: $shadow;
    border: $border;
    background-color: $white-color;
  }
  &_item:hover {
    box-shadow: 0px 0px 15px 0 hsla(349, 91%, 57%, 0.698);
  }
  &_image {
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &_name {
    font-weight: 700;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 0;
  }
  &_price {
    margin-top: 5px;
    color: $red-color;
    font-weight: 700;
    margin-bottom: 0;
  }
  &_desc {
    margin-top: 10px;
    padding-top: 10px;
    // border-top: $border;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-item {
      display: flex;
      align-items: baseline;

      svg {
        font-size: 20px;
        color: $grayDark-color;
        margin-right: 5px;
      }
      &-text {
        color: $grayDark-color;
        margin-bottom: 0;
      }
    }
  }
  &_book {
    margin-top: 10px;
    width: 90%;
    padding: 5px;
    width: 100%;
    text-align: center;
    color: white;
  }
}

.visible {
  position: absolute;
  top: 0;
  z-index: 100;
}
