@import "../../styles/variable";

.inputItem {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  input {
    width: 100%;
    border: $border;
    padding: 10px;
    border-radius: 10px;
    &:focus-visible {
      border: 1px solid;
      border-color: #40a9ff;
      box-shadow: $shadow-blur;
    }
    &:hover {
      box-shadow: $shadow-blur;
      border: 1px solid;
      border-color: #40a9ff;
    }
  }
}

.errorMessage {
  font-size: 12px;
  color: $red-color;
  font-weight: 700;
  position: absolute;
  bottom: -18px;
  left: 15px;
}
