@import "../../styles/variable";

.cardIntro {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: 2px 2px 7px 2px #00000045;
  cursor: pointer;
  &_image {
    width: 100%;
    height: 300px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &_content {
    position: absolute;
    top: 5%;
    left: 5%;
    padding: 5px 10px;
    background-color: #d0d0d0a5;
    border-radius: 10px;
    p {
      color: $white-color;
    }
  }
  &_label {
    font-size: 30px;
    line-height: calc(36 / 30);
    font-weight: 700;
    text-transform: uppercase;
  }
  &_sub {
    font-size: 18px;
    display: flex;
  }

  &_icon {
    font-size: 20px;
    margin-left: 5px;
    width: fit-content;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
  }
  &_icon:hover {
    animation-name: bounce;
    animation-timing-function: ease;
  }
  @keyframes bounce {
    0% {
      transform: translateX(0);
    }
    2% {
      transform: translateX(2px);
    }
    4% {
      transform: translateX(-2px);
    }
    6% {
      transform: translateX(2px);
    }
    8% {
      transform: translateX(-2px);
    }
    10% {
      transform: translateY(0px);
      transform: rotate(-20deg);
    }
    50% {
      transform: translateX(300px);
    }
    100% {
      transform: translateX(0);
    }
  }
}

@media screen and(max-width:599px) {
  .category {
    &_grid {
      grid-template-columns: repeat(1, 1fr);
    }
    &_image {
      height: 100px;
    }
  }
}
