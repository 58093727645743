@import "../../styles/variable";

.textareaItem {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  label {
    font-weight: 500;
  }

  textarea {
    width: 100%;
    height: 140px;
    border: $border;
    padding: 10px;
    border-radius: 10px;
    &:focus-visible {
      border: 1px solid;
      border-color: #40a9ff;
      box-shadow: $shadow-blur;
      outline: none;
    }
    &:hover {
      box-shadow: $shadow-blur;
      border: 1px solid;
      border-color: #40a9ff;
    }
  }
}
