@import "../../styles/variable";

body {
  --light: hsl(220, 50%, 90%);
  --primary: hsl(255, 30%, 55%);
  --focus: linear-gradient(to top, #ff0844 0%, #ffb199 100%);
  --border-color: hsla(0, 0%, 100%, 0.2);
  --global-background: hsl(220, 25%, 10%);
  --background: linear-gradient(-225deg, #22e1ff 0%, #1d8fe1 48%, #625eb1 100%);
  --shadow-1: hsla(236, 50%, 50%, 0.3);
  --shadow-2: hsla(236, 50%, 50%, 0.4);
}
/* modal */
.modal-container {
  background: var(--m-background);
  h1 {
    color: white;
  }
}
.modal-container:target {
  display: flex;
}

.modal {
  padding: 4rem 2rem;
  border-radius: 0.8rem;
  color: var(--light);
  background: var(--background);
  box-shadow: var(--m-shadow, 0.4rem 0.4rem 10.2rem 0.2rem) var(--shadow-1);
  position: relative;
}

.modal__title {
  font-size: 3.2rem;
  text-align: center;
}

.modal__text {
  text-align: center;
  padding: 0 4rem;
  margin-top: 1rem;
  font-size: 1.6rem;
  line-height: 2;
}

.modal__group {
  margin-top: 1rem;
  display: flex;
  justify-content: center;

  a {
    color: $white-color !important;
  }
}
.modal__btn {
  margin: 20px;
  padding: 1rem 1.6rem;
  border: 1px solid var(--border-color);
  border-radius: 100rem;
  color: inherit;
  background: transparent;
  font-size: 1.4rem;
  font-family: inherit;
  letter-spacing: 0.2rem;

  transition: 0.2s;
  cursor: pointer;

  a {
    color: #fff;
  }
}

.modal__btn:hover,
.modal__btn:focus {
  background: $red-color;
  border-color: $red-color;
  transform: translateY(-0.2rem);
  a {
    color: $white-color;
  }
}

.modal__close {
  width: 4rem;
  height: 4rem;
  border: 1px solid var(--border-color);
  border-radius: 100rem;
  color: inherit;
  font-size: 2.2rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.2s;
}

.modal__close::before {
  content: "×";
  transform: translateY(-0.1rem);
}

.modal__close:hover,
.modal__close:focus {
  background: var(--focus);
  border-color: var(--focus);
  transform: translateY(-0.2rem);
}

.box ~ div {
  background-color: red !important;
}
