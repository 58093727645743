@import "../../styles/variable";

.success {
  padding: 40px 0;
  &_car {
    .card_detail {
      background-color: $white-color;
      display: grid;
      grid-template-columns: 2fr 1fr;
      &_content {
        width: 100%;
        &-name {
          text-align: center;
          margin-top: 20px;
        }
        &-image {
          width: 100%;
        }
        &-price {
          display: none;
        }
      }
      &_desc {
        width: 100%;
        padding-left: 0;
      }
    }
  }
  &_info {
    border-radius: 8px;
    padding: 20px;
    box-shadow: $shadow;
    border: $border;
  }
  &_mess {
    max-width: 700px;
    width: 100%;
    margin: auto;
    margin-top: 10px;
    color: $black-color;
  }
  &_form {
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    max-height: 460px;
    height: 100%;
    overflow-y: auto;
    label,
    h3,
    p {
      color: $white-color;
    }
    p {
      span {
        color: $white-color;
      }
    }
    input,
    textarea,
    .ant-picker {
      background-color: $white-color !important;
    }
    .filledLocation {
      border-top: $border;
      margin-top: 20px;
      padding-top: 20px;
    }
  }

  &_form::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &_form::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  &_form::-webkit-scrollbar-thumb {
    background-color: $grayDark-color;
    border-radius: 5px;
  }
  &_service {
    label,
    h3 {
      color: $black-color;
    }
    &-heading {
      margin-bottom: 0;
    }
    &-group {
      padding-left: 5px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .checkboxItem {
        margin-top: 20px;
        margin-right: 30px;
      }
    }
  }
  &_total {
    display: flex;
    justify-content: space-between;
    h2 {
      color: $black-color;
    }
    p {
      color: $green-color;
    }
  }
  &_button {
    padding: 15px 40px;
    margin: 20px auto;
    color: white;
  }

  &_message {
    margin: auto;
    padding: 20px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: #5fe8a529;
    border: 1px solid $green-color;

    svg {
      margin-right: 20px;
      font-size: 50px;
      color: $green-color;
      box-shadow: 0px 0px 20px 3px $green-color;
      border-radius: 50%;
    }
  }
}

.successFail {
  margin-bottom: 50px;
  &_container {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    align-items: center;
  }
  &_content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h1 {
      margin-top: 20px;
    }
    p {
      margin-top: 20px;
    }
  }
  &_button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size: 20px;
    .button_primary {
      margin-right: 20px;
      padding: 15px;
    }
  }
  &_image {
    width: auto;
    height: 600px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.toggle {
  display: flex;
  padding: 10px;
  width: 60px;
  height: 40px;
  background-color: gray;
  border-radius: 50px;
  transition: all 3s linear;
}
.spin {
  transition: all 3s linear;

  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
}

.isActive {
  background-color: blue;
  .spin {
    background-color: gray;
    transform: translateX(15px);
  }
}

.gutter-row {
  background-color: blue;
  padding: 8px;
}

.success_title {
  text-transform: uppercase;
  color: #7c7c7c;
}
.success_content {
  font-weight: 700;
}
.success_item {
  border-left: 1px solid #e1e1e1;
}
.success_area {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 10px;
  border-radius: 10px;
  cursor: none;
}
