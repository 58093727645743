@import "../../styles/variable";
.menu {
  &_list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -20px;
  }
  &_item {
    margin: 0 20px;
    position: relative;
    a {
      color: $primary-color;
      font-size: 18px;
      line-height: calc(23 / 18);
      font-weight: 500;
    }
    a:hover {
      color: $red-color;
    }
  }
  &_active::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -10px;
    left: 0;
    background-color: $red-color;
  }

  & {
    aside {
      background-color: #c4a8ff;
      width: 18.75rem;
      height: 100vh;
    }

    .asd {
      margin: 4.5rem 1.4rem;
    }

    .btn-container {
      position: fixed;
    }

    .asd a {
      color: red;
      text-decoration: none;
      font-size: 1.75rem;
      font-weight: 600;
      display: block;
      margin: 20px;
    }

    button {
      cursor: pointer;
      margin: 1.25rem;
      border: none;
      padding: 0.5rem 1rem;
      background-color: #f9fafb;
    }
  }
}
