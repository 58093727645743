@import "../../styles/variable";
.search_location {
  .ant-select-selector {
    border-radius: 10px !important;
    padding: 10px;
    width: 300px;
    height: 44px !important;
    align-items: center;
    input {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
.flag {
  width: 25px;
  margin-right: 10px;
}
