.filledLocation {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  &_date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search_location-item {
      width: 48%;
    }
  }

  .inputItem {
    margin-top: 25px;
  }
}
