@import "../../styles/variable";
.header {
  .menuSlide {
    display: none;
  }
  background-color: $gray-bg;
  position: sticky;
  position: -webkit-sticky;
  top: 0; /* required */
  z-index: 1000;
  box-shadow: 2px 2px 4px 2px #e3e3e3;
  &_container {
    background-color: $gray-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 15px 0;
  }
  &_logo {
    width: 170px;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &_hotline {
    color: $primary-color;
    font-size: 18px;
    line-height: calc(23 / 18);
    font-weight: 700;
  }

  .ant-select-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px !important;
    border-radius: 10px !important;
    background-color: #a1a1a147 !important;

    svg {
      margin-right: 5px;
    }

    .ant-select-selection-item {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .ant-select-item-option-content {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;

    svg {
      margin-right: 10px !important;
    }
  }

  @media screen and(max-width: 767px) {
    .menu,
    .header_logo,
    .header_hotline {
      display: none;
    }
    .menuSlide {
      display: block;
    }
    .header_container {
      height: 75px;
    }
  }
}
