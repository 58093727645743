@import "../../styles/variable";

.car {
  &_info {
    padding: 30px 0;
    background-color: $gray-bg;
    &-button {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        padding: 14px 40px;
      }
    }
  }
  &_suggest {
    padding: 30px 0;
    h1 {
      text-align: center;
    }
  }
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number-group > .ant-input-number:first-child,
.ant-input-number-group-addon:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-addon:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.swiper {
  padding: 30px 0;
}
.swiper-grid-column > .swiper-wrapper {
  flex-direction: row;
  grid-gap: 60px 0;
}
