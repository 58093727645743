@import '~pretty-checkbox/src/pretty-checkbox.scss';
@import "../../styles/variable";

.checkboxItem {
  width: fit-content;
  input {
    padding: 30px;
    transform: scale(2);
  }
  label {
    margin-left: 10px;
    white-space: nowrap;
  }
}
