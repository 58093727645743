@import "../../styles/variable";

.footer {
  padding: 50px 0;
  border-top: $border;
  &_container {
    display: grid;
    grid-template-columns: 3fr 1fr 2fr;
    grid-gap: 30px;
    justify-content: space-between;
    align-items: flex-start;
  }
  &_col {
    align-content: center;
    align-items: center;
    margin: 0 auto;
    width: fit-content;
  }
  &_logo {
    height: 100px;
    width: fit-content;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &_contact {
    margin-top: 30px;
    &-item {
      padding: 10px 0;
      font-size: 18px;
      font-weight: 500;
      // line-height: calc(23 / 18);
      color: $primary-color;
    }
  }
  &_social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 -20px;
    margin-top: 30px;

    &-item {
      svg {
        padding: 0 20px;
        color: $primary-color;
        font-size: 50px;
        cursor: pointer;
      }
    }
  }
  &_page {
    display: flex;
    flex-direction: column;
    margin: -10px 0;
    &-link {
      cursor: pointer;
      font-size: 24px;
      font-weight: 700;
      line-height: calc(23 / 18);
      color: $primary-color;
      padding: 10px 0;
      &:hover {
        color: $red-color;
      }
    }
  }
  &_pay {
    &List {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }
    &Item {
      width: 100%;
      height: 70px;
      border: $border;
      box-shadow: $shadow;
      padding: 5px;
      border-radius: 8px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &_copyright {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
}

@media screen and(max-width:599px) {
  .footer {
    &_container {
      grid-template-columns: repeat(1, 1fr);
    }
    &_social {
      margin: 0;
      &-item {
        svg {
          padding: 0 20px 0 0;
        }
      }
    }
    &_col{
      width: 100%;
    }
    &_page{
      width: 100%;
      flex-direction: row;
      margin: 0;
      justify-content: space-between;
    }
  }
}
