@import "../../styles/variable";

.hero {
  background-color: $gray-bg;
  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 590px;
    position: relative;
    padding: 20px 0;
  }
  &_content {
    width: 50%;
    &-heading {
      width: 100%;
      font-size: 36px;
      line-height: calc(44 / 36);
      font-weight: 700;
      color: $primary-color;
      position: relative;
    }
    &-heading::before {
      content: "";
      width: 100px;
      height: 5px;
      background-color: $red-color;
      position: absolute;
      bottom: -25px;
    }
  }
  &_title {
    margin: -10px 0;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-item {
      padding: 10px 0;
      padding-left: 30px;
      position: relative;
    }
    &-item::before {
      content: "";
      width: 12px;
      height: 12px;
      background-color: $red-color;
      position: absolute;
      border-radius: 50%;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &_banner {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px;
    margin-right: 0;
    border-radius: 20px;
    background-color: $primary-color;
  }
  &_search {
    label {
      color: $white-color !important;
    }
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    align-items: flex-end;
    justify-content: space-between;
    width: fit-content;
    padding: 20px 40px;
    box-sizing: border-box;
    border-radius: 20px;
    margin: auto;
    background-color: #857e7ec2;

    &-container {
      padding: 80px 0;
      background-image: url("../../../public/image/search_bg2.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
    }
    &-pickup,
    .hero_search-return {
      .ant-picker {
        padding: 10px;
        border-radius: 10px;
        width: 100%;
      }
    }
    &-button {
      padding: 10px 40px;
      border: 1px solid $primary-color;
      transition: 0.3s;
      svg {
        margin-left: 10px;
      }
    }
    &-provinces {
      display: flex;
      flex-direction: column;
      label {
        span {
          color: red;
        }
      }
      .ant-picker {
        padding: 10px;
        border-radius: 10px;
      }
      .ant-select-selector {
        height: 100% !important;
        padding: 6px 13px !important;
        border-radius: 10px !important;
      }
    }
    .ant-select-selection-search {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
.brand {
  margin-top: 100px;
  padding: 20px;
  &_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_item {
    margin: 0 20px;
    height: 80px;
    width: auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.category {
  // background-color: $gray-bg;
  padding: 30px 0;
  position: relative;
  &_grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
  &_heading {
    text-align: center;
    width: fit-content;
    margin: 0 auto;
  }
  &_filter {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &-list {
      display: flex;
      margin-top: 20px;
    }
    &-item {
      margin-right: 20px;
      &-heading {
        margin-bottom: 5px;
        padding-left: 10px;
      }
      .ant-select-selector {
        border-radius: 10px !important;
      }
    }
  }
  &_gallery {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }
  &_button {
    width: fit-content;
    margin: 0 auto;
    margin-top: 30px;
    padding: 12px 50px;
  }
}

.featured {
  &_heading {
    width: fit-content;
    margin: 0 auto;
    margin-top: 50px;
    color: $red-color !important;
  }
  &_list {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.strength {
  background-color: $primary-color;
  margin-top: 100px;
  &_container {
    height: 380px;
    display: flex;
    align-items: center;
  }
  &_content {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &-heading {
      color: $white-color !important;
      text-transform: capitalize;
    }
    &-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: -10px 0;
    }
    &-item {
      display: flex;
      padding: 10px 0;
      align-items: center;
      svg {
        margin-right: 20px;
        font-size: 30px;
        color: $red-color;
        border-radius: 10px;
        padding: 5px;
        background-color: $white-color;
        box-shadow: 3px 3px 5px $red-color;
      }
      p {
        width: fit-content;
        height: fit-content;
        color: $white-color;
      }
    }
  }
  &_image {
    width: 50%;
    height: 100%;
    img {
      width: auto;
      height: 130%;
      transform: translateY(-20%);
      object-fit: contain;
    }
  }
}

.modal_home {
  background-color: red;
  overflow: hidden;
}

@media screen and(max-width:1023px) {
  .hero {
    &_container {
      flex-direction: column;
      height: fit-content;
    }
    &_content {
      width: fit-content;
    }
    &_banner {
      width: 100%;
      margin: 20px;
    }
    &_image {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &_search {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and(max-width:767px) {
    .featured_list {
      margin-top: 0;
      flex-direction: column !important;
      .cardLux {
        margin: 30px 0;
      }
    }
    .brand {
      margin-top: 0;
    }
    .strength{
      &_container{
        
      }
      &_content{
        width: 100%;
      }
      &_image{
        display: none;
      }
    }
  }

  @media screen and(max-width:599px) {
    .hero {
      &_search {
        &-container {
          padding: 20px;
        }
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .featured_list {
      padding: 30px;
    }
    .brand {
      &_container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
