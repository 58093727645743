@import "../../styles/variable";

.filled {
  padding: 40px 0;
  &_heading {
    margin-bottom: 0;
    text-align: center;
  }
  &_form {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  &_service {
    margin-top: 40px;
    &-heading {
      margin-bottom: 0;
      text-align: center;
    }
    &-group {
      padding-left: 5px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      .checkboxItem {
        margin-top: 20px;
        margin-right: 30px;
      }
    }
  }
  &_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    .button_red {
      padding: 15px 40px;
    }
    .button_primary {
      box-sizing: border-box;
      padding: 15px 80px;
      font-size: 20px;
    }
  }
  .filledInfo_date {
    .ant-picker {
      background: #e3e3e3;
      pointer-events: none;
    }
  }
}
