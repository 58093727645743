@import "../../styles/variable";

.search_location-item {
  position: relative;
}
.errorMessage {
  font-size: 12px;
  color: $red-color;
  font-weight: 700;
  position: absolute;
  bottom: -18px;
  left: 15px;
}
