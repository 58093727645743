@import "../../styles/variable";

.confirm {
  padding: 40px 0;

  label,
  h3,
  p {
    color: $white-color !important;
  }
  p {
    span {
      color: $white-color;
    }
  }

  input,
  textarea,
  .ant-picker {
    background-color: rgb(220, 220, 220) !important;
  }

  .card_detail {
    background-color: transparent;
    p,
    svg,
    h6 {
      color: $white-color !important;
    }

    .card_detail_content-price {
      display: none;
    }
  }
  &_container {
    margin-top: 30px;
    background-color: $primary-color;
    padding: 30px;
    border-radius: 20px;
  }

  &_heading {
    margin-bottom: 0;
    text-align: center;
  }
  &_form {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  &_service {
    margin-top: 40px;
    &-heading {
      margin-bottom: 0;
      text-align: center;
    }
    &-group {
      padding-left: 5px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      .checkboxItem {
        margin-top: 20px;
        margin-right: 30px;
      }
    }
  }
  &_pay {
    margin-top: 40px;
    &-heading {
      text-align: center;
      margin-bottom: 0;
    }
  }
  &_total {
    margin-top: 40px;
    h2 {
      text-align: center;
      color: $white-color;
      span {
        color: $green-color;
      }
    }
  }
  &_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    .button_red {
      padding: 15px 40px;
    }
    .button_primary {
      box-sizing: border-box;
      padding: 15px 80px;
      background-color: $green-color;
      font-size: 20px;
    }
  }

  .ant-picker-input > input[disabled] {
    color: $black-color;
  }

  .inputItem,
  .search_location-item {
    label {
      span {
        display: none;
      }
    }
  }
}
