$primary-color: #354167;
$blue-color: #1890ff;
$red-color: #f23557;
$green-color: #15ce1c;
$gray-bg: #eeeff1;
$gray-color: #e1e1e1;
$grayDark-color: #b1b1b1;
$white-color: #fff;
$black-color: #000;
$shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-blur: 2px 2px 4px 0 rgba(0, 0, 0, 0.144);
$border: 1px solid #d9d9d9;
