.text_heading {
  font-size: 36px;
  line-height: calc(44 / 36);
  font-weight: 700;
  color: $primary-color;
}

.text_l {
  font-size: 24px;
  font-weight: 700;
  line-height: calc(30 / 24);
  color: $primary-color;
}
.text_m {
  font-size: 18px;
  font-weight: 500;
  line-height: calc(24 / 18);
  color: $primary-color;
}
.text_s {
  font-size: 16px;
  font-weight: 400;
  line-height: calc(20 / 14);
  color: $primary-color;
}
