@import "../../styles/variable";

.filledInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &_date {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 20px;
    justify-content: space-between;
    align-items: center;
    .search_location-item {
      margin: 0;
    }
    .ant-picker {
      width: 100%;
    }
  }
  .inputItem {
    width: 49%;
    margin-top: 25px;
    ul {
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(110%);
      width: 100%;
      background-color: white;
      z-index: 100;
      border-radius: 10px;
    }
    li {
      cursor: pointer;
      border-bottom: 1px solid #eeeeee;
      padding: 10px;
    }
    li:hover {
      transition: all 0.2s linear;
      background-color: rgb(232, 232, 232);
    }
  }
  @media screen and(max-width:1023px) {
  }
}
