@import "../../styles/variable";

.cars {
  .search {
    margin-top: 30px;
    &_container {
      display: flex;
      flex-direction: column;
    }
    &_item {
      width: 100%;
      justify-content: center;
      align-items: flex-end;
      gap: 0 30px;
      display: flex;
      margin: 10px 0;
      &-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        p {
          span {
            display: none;
          }
        }
      }
      label {
        span {
          display: none;
        }
      }
    }

    &_group {
      margin-top: 30px !important;
      display: flex;
      flex-direction: column;
      &-heading {
        width: fit-content;
      }
    }

    &_filter {
      display: flex;
      align-items: center;
      p {
        display: block;
      }
      .button_filter {
        margin-right: 20px;
      }
    }

    &_option {
      &-item {
        margin: 0 5px !important;
      }
      .ant-select-selector {
        border-radius: 10px !important;
        padding: 10px;
        height: 32px !important;
        input {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
    &_button {
      width: fit-content;
      font-size: 20px;
      padding: 8px 20px;
      height: fit-content;
    }

    .ant-select-selection-item {
      color: $black-color;
    }

    .ant-input-number-handler-wrap {
      display: none;
    }

    .ant-input-number-group > .ant-input-number:first-child,
    .ant-input-number-group-addon:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .ant-input-number-group > .ant-input-number:last-child,
    .ant-input-number-group-addon:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .category {
    padding: 0;

    .swiper {
      padding: 20px 0;
      padding-bottom: 40px;
      z-index: 0;
    }
    .swiper-grid-column > .swiper-wrapper {
      flex-direction: row;
      grid-gap: 30px 0;
    }

    .ant-input-number-input {
      height: 46px;
    }
    &_none {
      text-align: center;
    }
  }

  .featured_list {
    margin-bottom: 50px;
  }
}
