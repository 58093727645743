@import "../../styles/variable";

.card_detail {
  background-color: $gray-bg;
  display: flex;
  align-items: center;
  &_content {
    width: 50%;
    &-name {
      text-align: center;
    }
    &-image {
      margin: 5px 0;
      width: 100%;
      height: 380px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-price {
      text-align: center;
      color: $red-color;
      span {
        color: $primary-color;
      }
    }
  }
  &_desc {
    width: 50%;
    padding-left: 50px;
    &-heading {
      color: $black-color;
    }
    p {
      svg {
        width: 30px;
        margin-right: 10px;
      }
      padding: 15px 0;
    }
  }
}
