@import "../../styles/variable";

.button_filter {
  border-radius: 10px;
  padding: 10px 20px;
  border: 1px solid $primary-color;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  &-image {
    display: flex;
    width: 100%;
    height: 32px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  p {
    text-align: center;
    width: 100%;
  }
}

.button_filter:hover {
  box-shadow: $shadow;
  transition: 0.3s;
}

.button_isChecked {
  background-color: $primary-color;
  box-shadow: $shadow;
  transition: 0.3s;

  p {
    color: $white-color;
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
