body {
  line-height: 1;
  color: black;
  background: white;
  font-family: "Calibri" !important;
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}

button {
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  display: block;
  white-space: nowrap;
}

input {
  border-style: none;
  outline: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  display: block;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.container {
  box-sizing: border-box;
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
}

.button {
  &_primary {
    background-color: $primary-color;
    color: white;
    border-radius: 10px;

    cursor: pointer;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  &_red {
    background-color: $red-color;
    color: white;
    border-radius: 10px;
    border: 1px solid $red-color;
  }
  &_red:hover,
  &_primary:hover {
    box-shadow: $shadow;
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  &_primary:focus {
    outline: none;
  }
}

.gradient_blue {
  background-image: linear-gradient(
    to right,
    #25aae1,
    #4481eb,
    #04befe,
    #3f86ed
  );
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}

.gradient_red {
  background-image: linear-gradient(
    to right,
    #ed6ea0,
    #ec8c69,
    #f7186a,
    #fbb03b
  );
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
}

.gradient_green {
  background-image: linear-gradient(
    to right,
    #25aae1,
    #40e495,
    #30dd8a,
    #2bb673
  );
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}
